import * as React from 'react';
import { AppBar, Logout, UserMenu, useTranslate, BooleanInput } from 'react-admin';
import { Link } from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    Theme,
    IconButton,
    Stack
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Logo from '../assets/logo.png';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useNavigate } from 'react-router-dom';
import { type } from '@testing-library/user-event/dist/type';
import { createLogicalOr } from 'typescript';
import { WEBSOCKET_URL, BACKEND_URL } from '../constants';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import LiveAgentRequest from './components/agent-request';
import { DataProvider } from '../context/ConversationContext'
import ConversationContext from '../context/ConversationContext';

// const ConfigurationMenu = React.forwardRef((props, ref) => {
//     const translate = useTranslate();
//     return (
//         <MenuItem
//             component={Link}
//             // @ts-ignore
//             ref={ref}
//             {...props}
//             to="/configuration"
//         >
//             <ListItemIcon>
//                 <SettingsIcon />
//             </ListItemIcon>
//             <ListItemText>{translate('app.configuration')}</ListItemText>
//         </MenuItem>
//     );
// });

// const MaterialUISwitch = styled(Switch)(({ theme }) => ({
//     width: 62,
//     height: 34,
//     padding: 7,
//     '& .MuiSwitch-switchBase': {
//       margin: 1,
//       padding: 0,
//       transform: 'translateX(6px)',
//       '&.Mui-checked': {
//         color: '#fff',
//         transform: 'translateX(22px)',
//         '& .MuiSwitch-thumb:before': {
//           backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//             '#fff',
//           )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
//         },
//         '& + .MuiSwitch-track': {
//           opacity: 1,
//           backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
//         },
//       },
//     },
//     '& .MuiSwitch-thumb': {
//       backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
//       width: 32,
//       height: 32,
//       '&:before': {
//         content: "''",
//         position: 'absolute',
//         width: '100%',
//         height: '100%',
//         left: 0,
//         top: 0,
//         backgroundRepeat: 'no-repeat',
//         backgroundPosition: 'center',
//         backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//           '#fff',
//         )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
//       },
//     },
//     '& .MuiSwitch-track': {
//       opacity: 1,
//       backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
//       borderRadius: 20 / 2,
//     },
// }));

const GoOfflineSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#4caf50',
        '&:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M17.657,17.657 c-1.171,1.171-2.734,1.828-4.414,1.828s-3.243-0.656-4.414-1.828C7.171,15.486,6.514,12.757,8.171,10.829L7.758,10.416 C6.343,12.057,6.943,14.571,8.757,16.385c1.814,1.814,4.328,2.414,5.943,0.999l0.414,0.414C15.243,17.243,12.514,17.9,17.657,17.657z M12,2.857c4.771,0,8.629,3.858,8.629,8.629S16.771,20.114,12,20.114S3.371,16.256,3.371,11.486S7.229,2.857,12,2.857z"/></svg>')`,
        },
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#6fbf73',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#BDBDBD',
    width: 32,
    height: 32,
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#CFCFCF',
    borderRadius: 20 / 2,
  },
}));


const UserAccountMenu = React.forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItem component={Link} 
                  // @ts-ignore 
                  ref={ref} {...props} to="/user-account">
            <ListItemIcon>
                <AccountBoxIcon />
            </ListItemIcon>{translate('app.editPassword')}
        </MenuItem>
    )
});

const CustomUserMenu = () => (
    <UserMenu>
        {/* <ConfigurationMenu /> */}
        <UserAccountMenu />
        <Logout />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    const translate = useTranslate()
    const agent_id = sessionStorage.getItem('agent_id')
    const { wsRef, isOnline, setIsOnline }: any = React.useContext(ConversationContext)
    const [status, setStatus] = React.useState(1);

    const handleSwitchChange = (event: any) => {
        setIsOnline(event.target.checked);
        setStatus(isOnline? 2:1)
    };

    React.useEffect(() => {
        if (status) {
            switchOnlineStatus();
        }
    }, [status]);

    const switchOnlineStatus = async () => {
      wsRef.current.send(JSON.stringify({action: 'offline_mode', target_id: agent_id, status: status}))
    };

    React.useEffect(() => {
        const fetchOnlineStatus = async () => {
          try {
            const response = await fetch(`${BACKEND_URL}/live-agent/${agent_id}`);
            const data = await response.json();
            sessionStorage.setItem("university_id", data.university)
            // if (data.status === 2) {
            //   setIsOnline(false)
            // } else {
            //   setIsOnline(true)
            // }
            // wsRef.current.send(JSON.stringify({action: 'offline_mode', target_id: agent_id, status: data.status}))
          } catch (error) {
            console.error("Failed to fetch status from backend:", error);
          }
        };
        fetchOnlineStatus();
    }, []);

    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={1}
            userMenu={<CustomUserMenu />}
            toolbar={<></>}
        >
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            {isLargeEnough && <img src={Logo}/> }
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
            {
                agent_id && <GoOfflineSwitch checked={isOnline} onChange={handleSwitchChange} name="offline-switch"/>
            }
            {
                agent_id && <LiveAgentRequest/>
            }
        </AppBar>
    );
};

export default CustomAppBar;
import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    useContext,
    useRef,
    useLayoutEffect
} from 'react'
import {
    Input,
    Divider,
    Avatar,
    Paper,
    Typography,
    ToggleButton,
    makeStyles,
    Box,
    IconButton,
    InputAdornment,
    Stack
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SvgIcon } from '@mui/material';
import { WEBSOCKET_URL, BACKEND_URL } from '../constants';

import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import SearchIcon from '@mui/icons-material/Search';
import { ActionReducer } from '../reducers/reducers';
import ConversationContext from '../context/ConversationContext';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ReactLoading from "react-loading";
import BlockIcon from '@mui/icons-material/Block';
import { Button, useNotify } from 'react-admin';
import { couldStartTrivia } from 'typescript';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Fab from '@mui/material/Fab';
import InfiniteScroll from 'react-infinite-scroll-component';

const ConversationMessage = () => {
    const { messages, setMessages, setTargetId, selectedAgentId, selectedSenderId, wsRef, targetId, inputDisabled, receiveHandover, agentIsHandler, selectedUniversityId, senderName, agentMessage, selectedBotChannel, setAgentMessage, setSocketioConnection, setSenderLead }: any = useContext(ConversationContext)
    const [shouldScroll, setShouldScroll] = useState(true);
    // const [isBottom, setIsAtBottom] = useState(false);
    const [isAtTop, setIsAtTop] = useState(false);

    const token = sessionStorage.getItem('token')
    const conversationRef = useRef<null | WebSocket>(null);
    const [messagesLimit, setMessagesLimit] = useState(50);
    const [added, setAdded] = useState(false);

    const messagesEndRef = useRef<HTMLDivElement>(null);
    const totalMessageRef = useRef(0)
    const agentMessageRef = useRef(0)

    const agent_id = sessionStorage.getItem('agent_id')
    const nameRef = useRef('')

    const containerRef = useRef<HTMLDivElement | null>(null);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const container = containerRef.current;
    //         if (!container) return;

    //         const scrollBottom = container.scrollHeight - container.clientHeight - container.scrollTop;
    //         if (scrollBottom === 0) {
    //             console.log('Scrolled to the top');
    //         }
    //     };

    //     const container = containerRef.current;
    //     if (container) {
    //         container.addEventListener('scroll', handleScroll);
    //     }

    //     return () => {
    //         if (container) {
    //             container.removeEventListener('scroll', handleScroll);
    //         }
    //     };
    // }, []);

    const handleScroll = () => {
        const scrollContainer = document.getElementById('scrollableDiv'); // Change to the actual ID of your scroll container
        if (scrollContainer) {
            console.log("scrollContainer -------->" + scrollContainer)
            console.log(scrollContainer.scrollHeight)
            console.log(scrollContainer.clientHeight)
            console.log(scrollContainer.scrollTop)
            console.log(scrollContainer.scrollHeight - scrollContainer.clientHeight + scrollContainer.scrollTop)
            if (scrollContainer.scrollHeight - scrollContainer.clientHeight + scrollContainer.scrollTop <= 1000) {
                if (!added) {
                    setMessagesLimit(messagesLimit + 50)
                    setAdded(true)
                }
            }
        }
    };


    function connect() {
        const ws = new WebSocket(`${WEBSOCKET_URL}/${selectedBotChannel}/${selectedSenderId}/${messagesLimit}`);

        ws.onopen = function () {
            console.log('Connection Opened');
        };

        ws.onmessage = function (event) {
            const data = JSON.parse(event.data);
            console.log(data)
            if (data.action === 'conversation' && data.target_id === selectedSenderId) {
                setMessages(data.content.reverse())
                setAgentMessage([])
                setTargetId(data.target_id)
                setSocketioConnection(data.on_session)
                setAdded(false)
                setSenderLead(data.lead)
            }
        };

        ws.onclose = function () {
            console.log('Connection closed');
        };

        return ws;
    }

    useEffect(() => {
        const ws = connect();

        return () => {
            ws.close();
        };
    }, [selectedSenderId, messagesLimit]);

    useEffect(() => {
        setMessagesLimit(50)
    }, [selectedSenderId]);


    // useEffect(() => {
    //     if (isAtTop === true) {
    //         console.log("1")
    //         setMessagesLimit(messagesLimit + 10)
    //         setIsAtTop(false)
    //     }
    //     console.log("2")
    //     // Add scroll event listener
    //     const scrollContainer = document.getElementById('scrollableDiv'); // Change to the actual ID of your scroll container
    //     if (scrollContainer) {
    //         scrollContainer.addEventListener('scroll', handleScroll);
    //         return () => {
    //             scrollContainer.removeEventListener('scroll', handleScroll);
    //         };
    //     }
    // }, []);

    // useEffect(() => {
    //     if (isAtTop === true) {
    //         console.log("1")
    //         setMessagesLimit(messagesLimit + 10)
    //         setIsAtTop(false)
    //     }
    //     console.log("2")
    //     // Add scroll event listener
    //     const scrollContainer = document.getElementById('myDiv'); // Change to the actual ID of your scroll container
    //     if (scrollContainer) {
    //         scrollContainer.addEventListener('scroll', handleScroll);
    //         return () => {
    //             scrollContainer.removeEventListener('scroll', handleScroll);
    //         };
    //     }
    // }, [isAtTop]);

    // function scrollToBottom() {
    //     // const element = document.getElementById("end");
    //     // element?.scrollIntoView({ block: 'end' });
    //     messagesEndRef.current?.scrollIntoView({ block: 'end' });
    // }

    const scrollToBottom = () => {
        const element = document.getElementById('element'); // Replace 'element' with the ID or reference to the element you want to scroll into view
        if (element) {
          element.scrollIntoView({ block: 'end' });
        }
    };

    useEffect(() => {
        totalMessageRef.current = 0
    }, [selectedSenderId]);

    useEffect(() => {
        if (agentMessage.length === 0) {
            agentMessageRef.current = 0
        }
    }, [agentMessage]);

    // useEffect(() => {
    //     if (messages.length > totalMessageRef.current && isAtTop === false) {
    //         scrollToBottom();
    //         totalMessageRef.current = messages.length
    //     } else {
    //         totalMessageRef.current = messages.length
    //     }
    // }, [messages]);

    // useEffect(() => {
    //     if (agentMessage.length > agentMessageRef.current && isAtTop === false) {
    //         scrollToBottom();
    //         agentMessageRef.current = agentMessage.length
    //     } else {
    //         agentMessageRef.current = agentMessage.length
    //     }
    // }, [agentMessage]);


    function formatCreatedAt(timestamp: string): string {
        const date = new Date(timestamp);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        const isToday = date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();

        const isYesterday = date.getDate() === yesterday.getDate() &&
            date.getMonth() === yesterday.getMonth() &&
            date.getFullYear() === yesterday.getFullYear();

        if (isToday) {
            const hours = date.getHours() % 12 || 12;
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const ampm = date.getHours() < 12 ? 'AM' : 'PM';
            return `Today, ${hours}:${minutes}${ampm}`;
        } else if (isYesterday) {
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `Yesterday, ${hours}:${minutes}`;
        } else {
            const month = date.toLocaleString('default', { month: 'short' });
            const day = date.getDate();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${month} ${day}, ${hours}:${minutes}`;
        }
    }

    // Broadcast Attachment URL to Live Agent
    const handleAttachmentIconClick = (event: any, attachment: any) => {
        const url = attachment + `?token=${token}`
        window.open(url);
    }

    const AgentAttachment = ({ agent, message, time, attachment, status_code, sender_name, displayName }: any) => {
        // const [displayName, setDisplayName] = useState(false);

        useEffect(() => {
            // Update the input value after the component mounts
            nameRef.current = sender_name ? sender_name : agent
        }, []);

        return (
            <Box display='flex' justifyContent='flex-end' p={1}>
                <Stack spacing={1} direction="column" >
                    {displayName === true &&
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent='flex-start' >
                            <Typography variant="caption">
                                {sender_name ? sender_name : agent}
                            </Typography>
                            <SvgIcon style={{ color: '#CFD8DC', fontSize: 40 }}><AccountCircleIcon /></SvgIcon>
                        </Stack>
                    }
                    <Box p={1} boxShadow={2} bgcolor="#e1f5fe" color="000000" flexWrap="wrap" maxWidth='65vh'>
                        {/* <Typography variant="body2" align="left">
                    <b>{sender_name?sender_name:agent}:</b>
                </Typography> */}
                        <IconButton onClick={(event) => handleAttachmentIconClick(event, attachment)}>
                            <AttachFileIcon fontSize='large' />
                        </IconButton>
                        <Typography variant="caption" align="left">
                            <p>{message}</p>
                        </Typography>
                        <Typography style={{ color: '#757575', fontSize: '12px' }} variant="body2" align="right">
                            <em>{formatCreatedAt(time)}</em>
                        </Typography>
                    </Box>
                </Stack>
            </Box>
        )
    }

    const UserAttachment = ({ user, message, time, attachment, status_code, sender_name, displayName }: any) => {
        // const [displayName, setDisplayName] = useState(false);

        useEffect(() => {
            // Update the input value after the component mounts
            nameRef.current = senderName ? senderName : user
        }, []);

        return (
            <Box display='flex' justifyContent='flex-start' p={1}>
                <Stack spacing={1} direction="column" >
                    {displayName === true &&
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent='flex-start' >
                            <SvgIcon style={{ color: '#CFD8DC', fontSize: 40 }}><AccountCircleIcon /></SvgIcon>
                            <Typography variant="caption">
                                {senderName ? senderName : user}
                            </Typography>
                        </Stack>
                    }
                    <Box p={1} boxShadow={2} bgcolor="#ffffff" color="000000" flexWrap="wrap" maxWidth='65vh'>
                        {/* <Typography variant="body2" align="left">
                    <b>{sender_name?sender_name:user}:</b>
                </Typography> */}
                        <IconButton onClick={(event): any => handleAttachmentIconClick(event, attachment)}>
                            <AttachFileIcon fontSize='large' />
                        </IconButton>
                        <Typography variant="caption" align="left">
                            <p>{message}</p>
                        </Typography>
                        <Typography style={{ color: '#757575', fontSize: '12px' }} variant="body2" align="right">
                            <em>{formatCreatedAt(time)}</em>
                        </Typography>
                    </Box>
                    {
                        status_code == 2 && <Box color="#f5f5f5"><ErrorOutlineIcon /> Failed to deliver</Box>
                    }
                </Stack>
            </Box>
        )
    }

    const UserMessage = ({ user, message, time, status_code, sender_name, displayName }: any) => {
        // const [displayName, setDisplayName] = useState(false);
        const notify = useNotify();
        // Event handler for copying the message to the clipboard
        const handleCopyMessage = () => {
            console.log("Message Copied!")
            // Create a temporary text area to perform the copy operation
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = message;
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            document.execCommand('copy');
            document.body.removeChild(tempTextArea);
            notify("Message Copied!")
        };

        useEffect(() => {
            // Update the input value after the component mounts
            nameRef.current = senderName ? senderName : user
        }, []);


        return (
            <Box display='flex' justifyContent='flex-start' p={1}>
                <Stack spacing={1} direction="column" >
                    {displayName === true &&
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent='flex-start' >
                            <SvgIcon style={{ color: '#CFD8DC', fontSize: 40 }}><AccountCircleIcon /></SvgIcon>
                            <Typography variant="caption">
                                {senderName ? senderName : user}
                            </Typography>
                        </Stack>
                    }
                    <Box borderRadius={1} p={1} alignItems="flex-start" boxShadow={2} bgcolor="#ffffff" color="#000000" whiteSpace='pre-wrap' maxWidth='65vh' flexWrap='wrap' onClick={handleCopyMessage} style={{ cursor: 'copy' }}>
                        {/* <Typography variant="body2" align="left">
                        <b>{sender_name?sender_name:user}:</b>
                    </Typography> */}
                        <Typography style={{ fontWeight: '400', fontSize: '14px' }} variant="body1" align="left">
                            <p>{message}</p>
                        </Typography>
                        <Typography style={{ color: '#757575', fontSize: '12px' }} variant="body2" align="right">
                            <em>{formatCreatedAt(time)}</em>
                        </Typography>
                    </Box>
                    {
                        status_code == 2 && <Box color="#f5f5f5"><ErrorOutlineIcon /> Failed to deliver</Box>
                    }
                </Stack>
            </Box>

        )
    }

    const BotMessage = ({ bot, message, time, status_code, sender_name, displayName }: any) => {
        // State to manage hover state
        const [isHovered, setIsHovered] = useState(true);
        const notify = useNotify();
        // Event handler for copying the message to the clipboard
        const handleCopyMessage = () => {
            // Create a temporary text area to perform the copy operation
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = message;
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            document.execCommand('copy');
            document.body.removeChild(tempTextArea);
            notify("Message Copied!")
        };

        useEffect(() => {
            // Update the input value after the component mounts
            nameRef.current = sender_name ? sender_name : bot
        }, []);

        return (
            <Box
                display='flex'
                justifyContent='flex-end'
                p={1}
            >
                <Stack spacing={1} direction="column" >
                    {displayName === true &&
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent='flex-end' >

                            <Typography variant="caption">
                                {sender_name ? sender_name : bot}
                            </Typography>
                            <SvgIcon style={{ color: '#CFD8DC', fontSize: 40 }}><AccountCircleIcon /></SvgIcon>

                        </Stack>
                    }
                    <Box borderRadius={1} p={1} alignItems="flex-start" boxShadow={2} bgcolor="#e1f5fe" color="#000000" whiteSpace='pre-wrap' maxWidth='65vh' flexWrap='wrap' onClick={handleCopyMessage} style={{ cursor: 'copy' }}>
                        {/* <Typography variant="caption" align="left">
                            <b>{sender_name?sender_name:bot}:</b>
                        </Typography> */}
                        {/* {   isHovered && 
                            <IconButton
                                aria-label="Copy message"
                                onClick={handleCopyMessage}
                                style={{
                                    position: 'absolute',
                                    top: '-20px',
                                    left: '-20px',
                                    backgroundColor: '#b4c4cb',
                                    fontSize: '8px'
                                }}
                            >
                                <FileCopyIcon />
                            </IconButton>
                        } */}
                        <Typography style={{ fontWeight: '400', fontSize: '14px' }} variant="body1" align="left">
                            <p>{message}</p>
                        </Typography>
                        <Typography style={{ color: '#757575', fontSize: '12px' }} variant="body2" align="right">
                            <em>{formatCreatedAt(time)}</em>
                        </Typography>

                    </Box>
                    {
                        status_code == 2 && <Box display="block" color="#757575" fontSize="small"><ErrorOutlineIcon /> Failed to deliver</Box>
                    }
                    {
                        status_code == 3 && <Box display="block" color="#b3b3b3" fontSize="small"><ErrorOutlineIcon /> Delivering ...</Box>
                    }
                </Stack>
            </Box>

        )
    }

    function getDisplayName(name: string, action: string) {
        const display = name ? name : action

        // current nameRef is different from the coming name
        if (nameRef.current !== display) {
            nameRef.current = display
            return true
        } else {
            nameRef.current = display
            return false
        }
    }

    // const fetchMoreData = () => {
    //     setTimeout(() => {
    //         setMessagesLimit(messagesLimit + 20)
    //     }, 1500);
    // };


    const fetchMoreData = () => {
        console.log("reached the top")
        // setMessagesLimit(messagesLimit + 50)
    };

    const [items, setItems] = useState(Array.from({ length: 20 }))

    return (
        <Fragment>
            {/* <div
            id="scrollableDiv"
            style={{
                height: 300,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
            }}
            >
            <InfiniteScroll
                dataLength={items.length}
                next={fetchMoreData}
                style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                inverse={true} //
                hasMore={true}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
            >
                {items.map((_, index) => (
                <div key={index}>
                    div - #{index}
                </div>
                ))}
            </InfiniteScroll>
            </div> */}

            <div
                ref={containerRef}
                id="scrollableDiv"
                style={{
                    height: '85%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    overflowX: 'hidden',
                    backgroundColor: '#fafafa'
                }}
            >
                <InfiniteScroll
                    dataLength={messages.length}
                    next={fetchMoreData}
                    scrollThreshold={0.7}
                    onScroll={handleScroll}
                    style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                    inverse={true} //
                    hasMore={true}
                    loader={<body> </body>}
                    scrollableTarget="scrollableDiv"
                >
                    {(!selectedAgentId || (agentIsHandler === true || receiveHandover === true || agent_id === selectedUniversityId?.toString())) &&
                        messages && messages.length > 0 && messages.map((message: any) => {

                            if (message.attachment_link && message.action == "user") {
                                return <UserAttachment user="User" time={message.created_at} message={message.attachment_name} attachment={message.attachment_link} status_code={message.status} sender_name={message.sender_name} displayName={getDisplayName(message.sender_name, 'User')} />
                            } else if (message.attachment_link && message.action == "live_agent") {
                                return <AgentAttachment agent="Live Agent" time={message.created_at} message={message.attachment_name} attachment={message.attachment_link} status_code={message.status} sender_name={message.sender_name} displayName={getDisplayName(message.sender_name, 'Live Agent')} />
                            } else if (message.action == "user" || message.sender == "user") {
                                return <UserMessage user="User" message={message.message} time={message.created_at} status_code={message.status} sender_name={message.sender_name} displayName={getDisplayName(message.sender_name, 'User')} />
                            } else if (message.action == "bot") {
                                return <BotMessage bot="Bot" message={message.message} time={message.created_at} status_code={message.status} displayName={getDisplayName(message.sender_name, 'Bot')} />
                            } else if (message.action == "live_agent" || message.sender == "live_agent") {
                                return <BotMessage bot="Live Agent" message={message.message} time={message.created_at} status_code={message.status} sender_name={message.sender_name} displayName={getDisplayName(message.sender_name, 'Live Agent')} />
                            }
                        })
                    }
                    {agentMessage && agentMessage.length > 0 && agentMessage.map((message: any) => {
                        if (message.attachment_link && message.action == "live_agent") {
                            return <AgentAttachment agent="Live Agent" time={message.created_at} message={message.attachment_name} attachment={message.attachment_link} status_code={message.status} sender_name={message.sender_name} displayName={getDisplayName(message.sender_name, 'Live Agent')} />
                        } else if (message.action == "live_agent") {
                            return <BotMessage bot="Live Agent" message={message.message} time={message.created_at} status_code={message.status} sender_name={message.sender_name} displayName={getDisplayName(message.sender_name, 'Live Agent')} />
                        }
                    })
                    }
                    {selectedAgentId && agentIsHandler === false && receiveHandover === false && agent_id !== selectedUniversityId?.toString() &&
                        <Paper style={{ overflowX: 'hidden', height: '100%', width: '100%', display: 'inline-flex', flexDirection: 'column', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundColor: 'rgba(52, 52, 52, 0.1)', opacity: .5 }}>
                            <BlockIcon />
                            <br />
                            <Typography>Live Conversation Going On, Please wait for Permission</Typography>
                        </Paper>
                    }
                </InfiniteScroll>
            </div>


            {/* <div id="end"></div> */}
            {/* <div ref={messagesEndRef} /> */}
            {/* {
                isBottom === false && 
                <Fab
                color="primary"
                size="small"
                aria-label="scroll to bottom"
                style={{
                    position: 'absolute',
                    marginLeft: '40%',
                    bottom: '10%',
                }}
                onClick={() => {scrollToBottom(); setIsAtBottom(true);}}
                >
                    <ArrowDownwardIcon />
                </Fab>
            }  */}
        </Fragment>
    )
}

export default ConversationMessage

import { Box, Divider } from '@mui/material';
import { useContext } from 'react'
import ConversationContext from '../../context/ConversationContext';
import { Table, TableRow, TableCell } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
      fontFamily: 'Roboto',
      fontSize: 16,
    },
});

export default function LeadDetails() {
    const { senderLead }: any = useContext(ConversationContext);

    return (
        (senderLead != null) ?
            <ThemeProvider theme={theme}>
                <Divider variant="fullWidth" />
                <Box sx={{ 'margin-left': '1em', 'margin-top': '2em', 'margin-bottom': '2em', 'display': 'flex', 'align-items': 'flex-start', 'flex-direction': 'column' }}>
                    <Table>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', padding: '2px', borderBottom: 'none' }}>Full Name</TableCell>
                            <TableCell style={{ padding: '2px', borderBottom: 'none' }}>{senderLead?.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', padding: '2px', borderBottom: 'none' }}>Email</TableCell>
                            <TableCell style={{ padding: '2px', borderBottom: 'none' }}>{senderLead?.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', padding: '2px', borderBottom: 'none' }}>Phone Number</TableCell>
                            <TableCell style={{ padding: '2px', borderBottom: 'none' }}>{senderLead?.contact_number}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', padding: '2px', borderBottom: 'none' }}>Nationality</TableCell>
                            <TableCell style={{ padding: '2px', borderBottom: 'none' }}>{senderLead?.nationality}</TableCell>
                        </TableRow>
                    </Table>
                </Box>
            </ThemeProvider>
            :
            <div></div>
    );
}
import { createContext, useState, useEffect, useRef, useReducer, useMemo } from "react";
//import axios from 'axios';
import { ActionReducer } from '../reducers/reducers';
import { WEBSOCKET_URL, BACKEND_URL } from '../constants';
import { combine2Validators } from "ra-core";
import axios from 'axios';
import { Snackbar, Alert } from '@mui/material';

// const checkLatency = async () => {
//   const startTime = performance.now();

//   try {
//     // Make an HTTP request to the server or API you want to measure the latency for.
//     const response = await axios.get('https://example.com/api/endpoint');

//     const endTime = performance.now();
//     const latency = endTime - startTime;

//     console.log('Request completed in ' + latency + ' milliseconds');
//     // You can do something with the response data here.
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };

// // Call the function to measure latency
// checkLatency();

const ConversationContext = createContext({});

export const DataProvider = ({ children }: { children: any }) => {
    const [selectedSenderId, setSelectedSenderId] = useState('')
    const [selectedRoomId, setSelectedRoomId] = useState('')
    const [selectedAgentId, setSelectedAgentId] = useState(null)
    const [selectedBotChannel, setSelectedRBotChannel] = useState('')
    const [selectedConversationTag, setSelectedConversationTag]: any = useState(null)
    const [chats, setConversationsList] = useState(null)
    const [mainConverationList, setReceiveConversationList] = useState(null)
    const [whatsappConversationList, setReceiveWhatsappConversationList] = useState(null)
    const [facebookConversationList, setReceiveFacebookConversationList] = useState(null)
    const [webchatConversationList, setReceiveWebchatConversationList] = useState(null)

    const [messages, setMessages] = useState<any[]>([]);
    const [agentMessage, setAgentMessage] = useState<any[]>([]);

    const [unreadMessages, setUnreadMessages]: any[] = useState([])
    const [targetId, setTargetId] = useState(null)
    const [inputDisabled, setInputDisabled] = useState(true)
    const listref = useRef(false)
    const [isPaused, setPause] = useState(false);
    const wsRef = useRef<null | WebSocket>(null);
    const [wsConn, setWsConn] = useState<null | WebSocket>(null);
    const [onIntercept, setOnIntercept] = useState(false)
    const [result, dispatch] = useState('')
    const [data, setData] = useReducer(ActionReducer, '')
    const [universityId, setUniversityId] = useState(null)
    const [agentId, setAgentId] = useState(null)
    const [agentInfo, setAgentInfo]: any = useState(null)
    const [loadAgent, setLoadAgent]: any = useState(false)
    const [pendingRequest, setPendingRequest]: any = useState(false)
    const [agentIsHandler, setAgentIsHandler]: any = useState(false)
    const [loadAgentPermission, setLoadAgentPermission]: any = useState(false)
    const [handoverAgentId, setHandoverAgentId]: any = useState(null)
    const [note, setNote] = useState("");
    const [transferButtonClicked, setTransferButtonClicked]: any = useState(false)
    const [selectedCreatedAt, setSelectedCreatedAt]: any = useState(null)
    const [selectedSessionStatus, setSelectedSessionStatus]: any = useState('Online')
    const [receiveHandover, setReceiveHandover]: any = useState(false)
    const [names, setNames] = useState<string[]>([]);
    const [tagName, setTagName] = useState<string[]>([]);
    const [botChannel, setBotChannel] = useState('all');
    const [filterBy, setFilterBy] = useState('');
    const [filterTag, setFilterTag] = useState('');
    const [filterID, setFilterID] = useState('');
    const [filter, setFilter] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [filterChannel, setFilterChannel] = useState('');
    const [selectedUniversityId, setSelectedUniversityId] = useState(null);
    const [socketioConnection, setSocketioConnection] = useState(true);
    const [userConnection, setUserConnection] = useState(true);
    const [subFilter, setSubFilter] = useState('');
    const [subFilterValue, setSubFilterValue] = useState('');
    const [filterName, setFilterName] = useState<string[]>([]);
    const [senderName, setSenderName] = useState(null);
    const [senderLead, setSenderLead] = useState(null);
    const [branch, setBranch] = useState('');
    const [department, setDepartment] = useState('');
    const [company, setCompany] = useState('');
    const [id, setId] = useState('');
    const [text, setText] = useState('');
    const [status, setStatus] = useState('');
    const [tags, setTags] = useState('');
    const [useFilter, setUseFilter] = useState(false);
    const [requestChat, setRequestChat] = useState(false);
    const [checked, setChecked] = useState(false);
    const [isOnline, setIsOnline] = useState(true);
    const [limit, setLimit] = useState(20);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const audioPlayer = useRef<HTMLAudioElement>(null);

    const identity = sessionStorage.getItem('identity')
    const agent_id = sessionStorage.getItem('agent_id')
    const access_token = sessionStorage.getItem('token')

    const loadAgentInfo = async () => {
        const response = await fetch(`${BACKEND_URL}/live-agent/${agentId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${access_token}`
            }
        })

        const data = await response.json();
        setAgentInfo(data);
        setLoadAgent(true)
    }


    // useEffect(() => {
    //     console.log(botChannel)
    //     if (botChannel === 'wa') {
    //         setConversationsList(whatsappConversationList);
    //     } else if (botChannel === 'fb') {
    //         setConversationsList(facebookConversationList);
    //     } else if (botChannel === 'webchat') {
    //         setConversationsList(webchatConversationList);
    //     } else {
    //         setConversationsList(mainConverationList)
    //     }
    // }, [botChannel]);
    const filterConversation = (chats: any) => {
        if (id !== '') {
            chats = chats.filter((conversation: any) => conversation.sender_id.includes(id));
        }

        if (checked !== false) {
            chats = chats.filter((conversation: any) => conversation.agent_id === agent_id);
        }

        if (status !== '') {
            chats = chats.filter((conversation: any) => conversation.conversation_tag === status);
        }

        if (tags !== '') {
            chats = chats.filter((conversation: any) => conversation.remark.includes(tags));
        }

        if (text !== '') {
            const getMatchedTextConversation = async () => {
                const response = await fetch(`${BACKEND_URL}/conversation-by-text/${text}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${access_token}`
                    }
                })

                const data = await response.json();
                return data;
            }
        }
        return chats;
    };

    useEffect(() => {
        if (botChannel === 'wa') {
            if (useFilter === true && whatsappConversationList) {
                const filteredConversation = filterConversation(whatsappConversationList)
                setConversationsList(filteredConversation)
            } else {
                setConversationsList(whatsappConversationList);
            }
        }
    }, [whatsappConversationList, botChannel]);

    useEffect(() => {
        if (botChannel === 'fb' && facebookConversationList) {
            if (useFilter === true) {
                const filteredConversation = filterConversation(facebookConversationList)
                setConversationsList(filteredConversation)
            } else {
                setConversationsList(facebookConversationList);
            }
        }
    }, [facebookConversationList, botChannel]);

    useEffect(() => {
        if (botChannel === 'webchat' && webchatConversationList) {
            if (useFilter === true) {
                const filteredConversation = filterConversation(webchatConversationList)
                setConversationsList(filteredConversation)
            } else {
                setConversationsList(webchatConversationList);
            }
        }
    }, [webchatConversationList, botChannel]);

    useEffect(() => {
        if (botChannel === 'all') {
            if (useFilter === true) {
                const filteredConversation = filterConversation(mainConverationList)
                setConversationsList(filteredConversation)
            } else {
                setConversationsList(mainConverationList);
            }
        }
    }, [mainConverationList, botChannel]);

    // useEffect(() => {
    //     if (botChannel === 'wa') {
    //         if (useFilter === true) {
    //             const filteredConversation = filterConversation(whatsappConversationList)
    //             setConversationsList(filteredConversation)
    //         } else {
    //             setConversationsList(whatsappConversationList);
    //         }
    //     } else if (botChannel === 'fb') {
    //         if (useFilter === true) {
    //             const filteredConversation = filterConversation(facebookConversationList)
    //             setConversationsList(filteredConversation)
    //         } else {
    //             setConversationsList(facebookConversationList);
    //         }
    //     } else if (botChannel === 'webchat') {
    //         if (useFilter === true) {
    //             const filteredConversation = filterConversation(webchatConversationList)
    //             setConversationsList(filteredConversation)
    //         } else {
    //             setConversationsList(webchatConversationList);
    //         }
    //     } else {
    //         if (useFilter === true) {
    //             const filteredConversation = filterConversation(mainConverationList)
    //             setConversationsList(filteredConversation)
    //         } else {
    //             setConversationsList(mainConverationList)
    //         }
    //     }
    // }, [mainConverationList, botChannel]);

    useEffect(() => {
        if (!agentId) return;
        loadAgentInfo();
    }, [agentId]);

    useEffect(() => {
        // Use worker to prevent tab inactive issue
        const worker = new Worker(new URL("../workers/worker.ts", import.meta.url));

        // Start the Web Worker with a 2 seconds
        worker.postMessage({ delay: 2000 });

        // Listen for the "tick" message from the Web Worker
        worker.onmessage = () => {
            loadData();
        };

        return () => worker.terminate();
    }, [limit, botChannel, agent_id, isOnline]);

    function playAudio() {
        if (audioPlayer.current) {
            audioPlayer.current.play();
        }
    }

    const loadData = async () => {
        if (sessionStorage.getItem('token')) {
            var notifiedSenderIdsArr: Array<any> = [];
            var notifiedSenderIds = sessionStorage.getItem('notified_sender_ids');

            if (notifiedSenderIds) {
                notifiedSenderIdsArr = JSON.parse(notifiedSenderIds);
            }

            if (agent_id && isOnline) {
                wsRef.current?.send(JSON.stringify({action: 'offline_mode', target_id: agent_id, status: 1}));
            }
    
            const response = await fetch(`${BACKEND_URL}/conversations/${limit}/${botChannel}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${access_token}`
                }
            })
            const data = await response.json();
    
            if (botChannel === 'wa') {
                setReceiveWhatsappConversationList(data.result);
            } else if (botChannel === 'fb') {
                setReceiveFacebookConversationList(data.result);
            } else if (botChannel === 'webchat') {
                setReceiveWebchatConversationList(data.result);
            } else {
                setReceiveConversationList(data.result);
            }

            var newNotifiedSenderIds: Array<any> = [];
            if (data.new_messages && data.new_messages.length > 0) {
                var channelsWithNewMessage: Array<string> = [];
                data.new_messages.forEach(function(obj: any) {
                    newNotifiedSenderIds.push(obj.sender_id);
                    if (!notifiedSenderIdsArr.includes(obj.sender_id) && !channelsWithNewMessage.includes(obj.bot_channel)) {
                        channelsWithNewMessage.push(obj.bot_channel);
                    }
                });

                if (channelsWithNewMessage.length > 0) {
                    for (let i = 0; i < channelsWithNewMessage.length; i++) {
                        if (channelsWithNewMessage[i] === "fb") {
                            channelsWithNewMessage[i] = "Facebook";
                        } else if (channelsWithNewMessage[i] === "wa") {
                            channelsWithNewMessage[i] = "Whatsapp";
                        }
                    }

                    setSnackbarMessage('New message from ' + channelsWithNewMessage.join(', '));
                    setSnackbarOpen(true);
                    playAudio();
                }
            }
            sessionStorage.setItem('notified_sender_ids', JSON.stringify(newNotifiedSenderIds));
        }
    }

    // const loadFilteredData = async () => {
    //     console.log(filterBy)
    //     console.log(filterValue)
    //     const response = await fetch(`${BACKEND_URL}/conversations?filter=${filterBy}&value=${filterValue}`, {
    //         method: "GET"
    //     })
    //     const data = await response.json();
    //     console.log(data.result)
    //     setConversationsList(data.result);
    // }

    const loadFilteredData = async () => {
        const url = new URL(`${BACKEND_URL}/conversations-by-filters`);
        if (branch !== '') {
            url.searchParams.append('branch', branch);
        }
        if (department !== '') {
            url.searchParams.append('department', department);
        }
        if (company !== '') {
            url.searchParams.append('company', company);
        }
        if (id !== '') {
            url.searchParams.append('id', id);
        }
        if (text !== '') {
            url.searchParams.append('text', text);
        }
        if (status !== '') {
            url.searchParams.append('status', status);
        }
        if (tags !== '') {
            url.searchParams.append('tags', tags);
        }
        if (checked !== false) {
            url.searchParams.append('agent', agent_id ? agent_id : '');
        }

        const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${access_token}`
        };

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        return fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setConversationsList(data.result);
            })
            .catch(error => {
                console.error(error);
                return error;
            });
    }

    const loadChannelData = async () => {
        const url = new URL(`${BACKEND_URL}/conversations-by-filters?channel=${filterChannel}&filter=${filterName}`);
        if (branch !== '') {
            url.searchParams.append('branch', branch);
        }
        if (department !== '') {
            url.searchParams.append('department', department);
        }
        if (company !== '') {
            url.searchParams.append('company', company);
        }
        if (id !== '') {
            url.searchParams.append('id', id);
        }
        if (text !== '') {
            url.searchParams.append('text', text);
        }
        if (status !== '') {
            url.searchParams.append('status', status);
        }
        if (tags !== '') {
            url.searchParams.append('tags', tags);
        }
        if (checked !== false) {
            url.searchParams.append('agent', agent_id ? agent_id : '');
        }

        const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${access_token}`
        };

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        return fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setConversationsList(data.result);
            })
            .catch(error => {
                console.error(error);
                return error;
            });
    }

    // const loadChannelData = async () => {
    //     const response = await fetch(`${BACKEND_URL}/conversations?channel=${filterChannel}&filter=${filterBy}&value=${filterValue}`, {
    //         method: "GET"
    //     })
    //     const data = await response.json();
    //     console.log(data.result)
    //     setConversationsList(data.result);
    // }

    // useEffect(() => {
    //     loadData();
    // }, []);

    // useEffect(() => {
    //     if (useFilter === false) return;

    //     if (filterChannel){
    //         loadChannelData();
    //     } else if (useFilter === true) {
    //         loadFilteredData();
    //     } 
    // }, [useFilter, filterChannel, branch, department, company, id, text, status, tags]);

    // useEffect(() => {
    //     if (filterChannel){
    //         loadChannelData();
    //     } else if (useFilter === true) {
    //         loadFilteredData();
    //     } 
    // }, [useFilter, filterChannel]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (filterChannel){
    //             loadChannelData();
    //         } else if (useFilter === true) {
    //             loadFilteredData();
    //         } 
    //     }, 10000)
    //     return () => clearInterval(interval)
    // }, [chats]);

    // START WEB SOCKET
    // avoid creating multiple websocket

    const [network, setNetwork] = useState(true)

    useEffect(() => {
        if (network == false) {
            console.log("No connection")
            const interval = setInterval(() => {
                connect();
            }, 5000)
            return () => clearInterval(interval)
        }
    }, [network])

    function connect() {
        wsRef.current = new WebSocket(`${WEBSOCKET_URL}/ws/`);

        wsRef.current.onopen = function () {
            console.log('Connection Opened')
            setNetwork(true);
            // wsRef.current?.send(JSON.stringify({ action: 'get_main_list' }))
            // wsRef.current?.send(JSON.stringify({ action: 'get_whatsapp_list' }))
            // wsRef.current?.send(JSON.stringify({ action: 'get_facebook_list' }))
            // wsRef.current?.send(JSON.stringify({ action: 'get_webchat_list' }))

        };
        wsRef.current.onclose = function () {
            setNetwork(false);
            console.log('Connection closed')
        };

        const wsCurrent = wsRef.current;

        return () => {
            wsCurrent.close();
        };
    }

    useEffect(() => {
        connect();
    }, []);

    //handle websocket send and receive
    useEffect(() => {
        if (!wsRef.current) return;

        // if (wsRef.current && result && result !== '') {
        //     wsRef.current.send(JSON.stringify(result))
        //     dispatch('')
        // }

        wsRef.current.onmessage = e => {
            if (isPaused) return;

            if (agent_id) {
                wsRef.current?.send(JSON.stringify({ action: 'establish_connection', target_id: agent_id }))
            }

            if (!agent_id) {
                wsRef.current?.send(JSON.stringify({ action: 'remove_connection' }))
            }

            const data = JSON.parse(e.data);
            console.log(data)

            // if (data.action === 'get_main_list') {
            //     setReceiveConversationList(data.result);
            // } else if (data.action === 'get_whatsapp_list') {
            //     setReceiveWhatsappConversationList(data.result);
            // } else if (data.action === 'get_facebook_list') {
            //     setReceiveFacebookConversationList(data.result);
            // } else if (data.action === 'get_webchat_list') {
            //     setReceiveWebchatConversationList(data.result);
            // } else if (data.action === 'offline_mode' && data.agent_id === agent_id && data.status === 1) {
            if (data.action === 'offline_mode' && data.agent_id === agent_id && data.status === 1) {
                setIsOnline(true)
            } else if (data.action === 'offline_mode' && data.agent_id === agent_id && data.status === 2) {
                setIsOnline(false)
            } else if (data.action === 'request_chat' && data.target_id === selectedSenderId) {
                setRequestChat(true)
            } else if (data.action === 'intercept' && data.target_id === selectedSenderId && data.agent_id !== agent_id) {
                setOnIntercept(true)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(false)
            } else if (data.action === 'intercept' && data.target_id === selectedSenderId && data.agent_id === agent_id) {
                setOnIntercept(true)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(true)
            } else if (data.action === 'end_intercept' && data.target_id === selectedSenderId) {
                setOnIntercept(false)
                setAgentId(null)
                setSelectedAgentId(null)
                setAgentIsHandler(false)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === false && data.content.on_pending && data.content.on_handover && data.handover_agent_id === agent_id) { // not on intercept, but on pending and on handover
                setOnIntercept(false)
                setAgentId(data.handover_agent_id)
                setSelectedAgentId(data.handover_agent_id)
                setReceiveHandover(true)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === false && data.content.on_pending && data.agent_id === agent_id) { // not on intercept, but on pending and is selected agent
                setOnIntercept(false)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(true)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === false && data.content.on_pending && data.agent_id !== agent_id) { // not on intercept, but on pending and not selected agent
                setOnIntercept(false)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(false)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === false) {
                setOnIntercept(false)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === true && data.content.on_handover && data.agent_id === agent_id) { // on intercept, on handover
                setOnIntercept(true)
                setAgentId(data.handover_agent_id)
                setSelectedAgentId(data.handover_agent_id)
                setAgentIsHandler(true)
                // setPendingRequest(true)
                // setSocketioConnection(data.content.on_session)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === true && data.content.on_handover && data.agent_id !== agent_id && data.handover_agent_id === agent_id) { // on intercept, on handover and is handover agent
                setOnIntercept(true)
                setAgentId(data.handover_agent_id)
                setSelectedAgentId(data.handover_agent_id)
                setReceiveHandover(true)
                // setSocketioConnection(data.content.on_session)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === true && data.agent_id === agent_id) { // on intercept and is selected agent
                setOnIntercept(true)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(true)
                // setSocketioConnection(data.content.on_session)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === true && data.agent_id !== agent_id) {
                setOnIntercept(true)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                // setSocketioConnection(data.content.on_session)
            } else if (data.action === 'handover' && data.target_id === selectedSenderId && data.agent_id === agent_id) {
                setOnIntercept(data.content.on_intercept)
                // setPendingRequest(true)
                setAgentId(data.handover_agent_id)
                setSelectedAgentId(data.agent_id)
            } else if (data.action === 'handover' && data.target_id === selectedSenderId && data.handover_agent_id === agent_id) {
                setOnIntercept(data.content.on_intercept)
                // setPendingRequest(false)
                setReceiveHandover(true)
                setAgentId(data.handover_agent_id)
                setSelectedAgentId(data.agent_id)
            } else if (data.action === 'accept_handover' && data.target_id === selectedSenderId && data.handover_agent_id === agent_id) {
                setOnIntercept(data.content.on_intercept)
                setAgentId(data.handover_agent_id)
                setAgentIsHandler(true)
                setSelectedAgentId(data.handover_agent_id)
                setReceiveHandover(false)
            } else if (data.action === 'accept_handover' && data.target_id === selectedSenderId && data.handover_agent_id !== agent_id) {
                setOnIntercept(data.content.on_intercept)
                setAgentId(data.handover_agent_id)
                setAgentIsHandler(false)
                setSelectedAgentId(data.handover_agent_id)
                setReceiveHandover(false)
            } else if (data.action === 'deny_handover' && data.target_id === selectedSenderId && data.agent_id === agent_id) {
                setOnIntercept(data.content.on_intercept)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(true)
                // setPendingRequest(false)
            } else if (data.action === 'deny_handover' && data.target_id === selectedSenderId && data.agent_id !== agent_id && data.handover_agent_id === agent_id) {
                setOnIntercept(data.content.on_intercept)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(false)
                setReceiveHandover(false)
            }
        };
    }, [isPaused, onIntercept, selectedSenderId, agent_id]);

    useEffect(() => {
        if (onIntercept === false) {
            setInputDisabled(true)
        } else if (onIntercept === true) {
            setInputDisabled(false)
        }
    }, [onIntercept]);

    // // Get Messages by selected sender ID
    // useEffect(() => {
    //     if (selectedSenderId){
    //         axios
    //         .get(`${BACKEND_URL}/conversation-by-id/${selectedSenderId}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${access_token}`,
    //                 'Content-Type': 'application/json'
    //             }})
    //         .then((res: any) => setMessages(res.data))
    //         .catch((err: any) => { console.warn(err) })
    //     }

    // }, [selectedSenderId]);

    useEffect(() => {
        // reinitialize all state on sender id change
        setAgentId(null)
        setUniversityId(null)
        setAgentInfo(null)
        setTransferButtonClicked(false)
        setAgentIsHandler(false)
        setNote("")
        setSocketioConnection(true)
        setUserConnection(true)
        setAgentMessage([])
    }, [selectedSenderId]);

    useEffect(() => {
        setConversationsList(null)
    }, [botChannel]);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <ConversationContext.Provider value={{
            // contains all the shared states needed across the conversation module
            chats, selectedSenderId, setSelectedSenderId,
            selectedConversationTag, setSelectedConversationTag,
            selectedAgentId, setSelectedAgentId,
            messages, setMessages, targetId, inputDisabled,
            result, dispatch, wsRef,
            onIntercept, unreadMessages, network,
            selectedRoomId, setSelectedRoomId,
            selectedBotChannel, setSelectedRBotChannel,
            universityId, setUniversityId,
            agentId, setAgentId,
            agentInfo, setAgentInfo,
            transferButtonClicked, setTransferButtonClicked,
            pendingRequest, agentIsHandler, setAgentIsHandler,
            loadAgent, setLoadAgent,
            loadAgentPermission, setLoadAgentPermission,
            selectedCreatedAt, setSelectedCreatedAt,
            handoverAgentId, setHandoverAgentId,
            selectedSessionStatus, setSelectedSessionStatus,
            receiveHandover, setReceiveHandover,
            note, setNote,
            names, setNames,
            tagName, setTagName,
            filterBy, setFilterBy,
            botChannel, setBotChannel,
            filterTag, setFilterTag,
            filterID, setFilterID,
            filter, setFilter,
            filterValue, setFilterValue,
            filterChannel, setFilterChannel,
            selectedUniversityId, setSelectedUniversityId,
            socketioConnection, setSocketioConnection,
            userConnection, setUserConnection,
            subFilterValue, setSubFilterValue,
            subFilter, setSubFilter,
            filterName, setFilterName,
            senderName, setSenderName,
            senderLead, setSenderLead,
            branch, setBranch,
            department, setDepartment,
            company, setCompany,
            id, setId,
            text, setText,
            status, setStatus,
            tags, setTags,
            useFilter, setUseFilter,
            requestChat, setRequestChat,
            checked, setChecked,
            agentMessage, setAgentMessage,
            setTargetId,
            isOnline, setIsOnline,
            limit, setLimit
        }}>
            {children}
            <audio ref={audioPlayer} src={'https://ucsi-bot.dbix.com.my/api/get-attachment/new_message_ringtone.mp3'} />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{
                    marginTop: '35px'
                }}
                open={snackbarOpen}
                autoHideDuration={10000}
                onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="info">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </ConversationContext.Provider>
    )
}

export default ConversationContext;
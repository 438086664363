import { useEffect, useState, useRef, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import { BACKEND_URL } from '../../constants'
import AudioPlayer from 'react-audio-player';
import ConversationContext from '../../context/ConversationContext';

import { useNavigate } from 'react-router-dom';
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Theme,
  IconButton,
  Stack
} from '@mui/material';
import { request } from 'http';
import { Button, useNotify } from 'react-admin';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme, color }) => ({
  width: 20,
  height: 20,
  fontSize: 14,
  color: color,
  backgroundColor: 'red'
}));

export default function LiveAgentRequest({color}: any) {
  const navigate = useNavigate()
  const notify = useNotify()
  const [whatsappCallInRequest, setWhatsappCallInRequest] = useState(0);
  const [facebookCallInRequest, setFacebookCallInRequest] = useState(0);
  const [webchatCallInRequest, setWebchatCallInRequest] = useState(0);
  const [whatsappChattingRequest, setWhatsappChattingRequest] = useState(0);
  const [facebookChattingRequest, setFacebookChattingRequest] = useState(0);
  const [webchatChattingRequest, setWebchatChattingRequest] = useState(0);
  const [whatsappHandoverRequest, setWhatsappHandoverRequest] = useState(0);
  const [facebookHandoverRequest, setFacebookHandoverRequest] = useState(0);
  const [webchatHandoverRequest, setWebchatHandoverRequest] = useState(0);
  const [whatsappDeniedRequest, setWhatsappDeniedRequest] = useState(0);
  const [facebookDeniedRequest, setFacebookDeniedRequest] = useState(0);
  const [webchatDeniedRequest, setWebchatDeniedRequest] = useState(0);

  const [totalRequest, setTotalRequest] = useState(0);
  const [alert, setAlert] = useState(false);
  const [notInterceptRequest, setNotInterceptRequest] = useState(0);
  const [prevNotInterceptRequest, setPrevNotInterceptRequest] = useState(0);
  const requestRef = useRef(notInterceptRequest)

  const { setBotChannel, setChecked, setUseFilter, wsRef }: any = useContext(ConversationContext)



  const agent_id = sessionStorage.getItem('agent_id')
  const fullname = sessionStorage.getItem('fullname')

  const audioPlayer = useRef<HTMLAudioElement>(null);
  const [unnotifiedRequest, setUnnotifiedRequest] = useState(0);



  function playAudio() {
    if (audioPlayer.current) {
      audioPlayer.current.play();
      setAlert(false)
    }
  }

  function openNotification() {
    var windowWidth = 400;
    var windowHeight = 200;
    var windowLeft = window.innerWidth - windowWidth;
    var windowTop = window.innerHeight;
  
    var notificationWindow = window.open("", "_blank", `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`);
  
    if (notificationWindow === null || typeof notificationWindow === "undefined") {
      // Popup blocked by the browser
      notify("Popup was blocked by the browser's popup blocker.");
    } else {
      // Popup opened successfully
      var htmlContent = `
        <html>
          <head>
            <title>(${totalRequest}) Live Agent Request</title>
            <style>
              body {
                background-color: #f1f1f1;
                margin: 0;
                padding: 20px;
                font-family: Arial, sans-serif;
              }
              
              h1 {
                font-size: 20px;
                color: #333;
              }
              
              p {
                color: #555;
              }
              
              .notification-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                background-color: #41d80b;
                border-radius: 50%;
                color: #fff;
                font-size: 24px;
                margin-bottom: 20px;
              }
            </style>
          </head>
          <body>
            <div class="notification-icon">
              <i class="fas fa-bell"></i>
            </div>
            <h1>Dear ${fullname},</h1>
            <p>You have received a new Live Agent Request, please attend.</p>
          </body>
        </html>
      `;
  
      notificationWindow.document.write(htmlContent);
    }
  }
  
  

  function closeNotification() {
    window.close();
  }

  const loadAgentRequest = async () => {
    const response = await fetch(`${BACKEND_URL}/get-agent-assigned-requests/${agent_id}`, {
        method: "GET"
    })
    const data = await response.json();
    setWhatsappCallInRequest(data.whatsappCallInRequest);
    setFacebookCallInRequest(data.facebookCallInRequest);
    setWebchatCallInRequest(data.webchatCallInRequest);
    setWhatsappChattingRequest(data.whatsappChattingRequest);
    setFacebookChattingRequest(data.facebookChattingRequest);
    setWebchatChattingRequest(data.webchatChattingRequest);
    setWhatsappHandoverRequest(data.whatsappHandoverRequest);
    setFacebookHandoverRequest(data.facebookHandoverRequest);
    setWebchatHandoverRequest(data.webchatHandoverRequest);
    setWhatsappDeniedRequest(data.whatsappDeniedRequest);
    setFacebookDeniedRequest(data.facebookDeniedRequest);
    setWebchatDeniedRequest(data.webchatDeniedRequest);
    setNotInterceptRequest(data.whatsappCallInRequest + data.facebookCallInRequest + data.webchatCallInRequest + data.whatsappHandoverRequest + data.facebookHandoverRequest + data.webchatHandoverRequest + data.whatsappDeniedRequest + data.facebookDeniedRequest + data.webchatDeniedRequest)

    setTotalRequest(data.whatsappCallInRequest + data.facebookCallInRequest + data.webchatCallInRequest + data.whatsappChattingRequest + data.facebookChattingRequest + data.webchatChattingRequest + data.whatsappHandoverRequest + data.facebookHandoverRequest + data.webchatHandoverRequest + data.whatsappDeniedRequest + data.facebookDeniedRequest + data.webchatDeniedRequest)
  }

  useEffect(() => {
    const checkForChange = () => {
      if (notInterceptRequest > requestRef.current) {
        setAlert(true);
      }
      requestRef.current = notInterceptRequest;
    };

    checkForChange();

    // Cleanup function to compare values when the component unmounts
    return () => {
      checkForChange();
    };
  }, [notInterceptRequest]);

  // useEffect(() => {
  //   if (notInterceptRequest > requestRef.current) {
  //     setAlert(true)
  //     requestRef.current = notInterceptRequest
  //   }
  // }, [notInterceptRequest]);

  useEffect(() => {
    if (alert === true) {
      playAudio()
      openNotification()
    }
  }, [alert]);

  useEffect(() => {
    loadAgentRequest();
  }, []);

  useEffect(() => {
    // Use worker to prevent tab inactive issue
    const worker = new Worker(new URL("../../workers/worker.ts", import.meta.url));

    // Start the Web Worker with a 5 seconds
    worker.postMessage({ delay: 5000 });

    // Listen for the "tick" message from the Web Worker
    worker.onmessage = () => {
      loadAgentRequest();
    };

    return () => worker.terminate();
  }, []);

  return (
    <Stack spacing={1} direction="row">
      <audio ref={audioPlayer} src={'https://ucsi-bot.dbix.com.my/api/get-attachment/mixkit-on-hold-ringtone-1361.mp3'} />
      {
        whatsappCallInRequest + whatsappChattingRequest + whatsappHandoverRequest + whatsappDeniedRequest > 0 && 
        <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
            <SmallAvatar color={color}>{whatsappCallInRequest + whatsappChattingRequest + whatsappHandoverRequest + whatsappDeniedRequest}</SmallAvatar>
        }
        >
            <IconButton onClick={(e) => {navigate("/conversations"); setBotChannel('wa'); setChecked(true); setUseFilter(true);}}><WhatsAppIcon sx={{fontSize: 30, color: '#25D366'}}/></IconButton>
        </Badge>
      }
      {
        facebookCallInRequest + facebookChattingRequest + facebookHandoverRequest + facebookDeniedRequest > 0 &&
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
              <SmallAvatar color={color}>{facebookCallInRequest + facebookChattingRequest + facebookHandoverRequest + facebookDeniedRequest}</SmallAvatar>
          }
        >
          <IconButton onClick={(e) => {navigate("/conversations"); setBotChannel('fb'); setChecked(true); setUseFilter(true);}}><FacebookOutlinedIcon sx={{fontSize: 30, color: '#1877F2'}}/></IconButton>
        </Badge>
      }
      { webchatCallInRequest + webchatChattingRequest + webchatHandoverRequest + webchatDeniedRequest > 0 &&
        <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
            <SmallAvatar color={color}>{webchatCallInRequest + webchatChattingRequest + webchatHandoverRequest + webchatDeniedRequest}</SmallAvatar>
        }
        >
          <IconButton onClick={(e) => {navigate("/conversations"); setBotChannel('webchat'); setChecked(true); setUseFilter(true);}}><ChatIcon sx={{fontSize: 30, color: '#2E7D32'}}/></IconButton>
        </Badge>
      }
    </Stack>
  );
}
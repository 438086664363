import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    createContext,
    useContext,
    useReducer,
    useMemo,
    useRef
} from 'react'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Box, IconButton, Input, Divider, Avatar, Paper, Typography, ToggleButton, Button, Stack } from '@mui/material';
import ConversationContext from '../context/ConversationContext';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';
import { WEBSOCKET_URL, BACKEND_URL } from '../constants';
import MultipleSelectChip from './components/select-chip';
import CountdownTimer from './components/countdown-timer';
import SessionStatus from './components/session-status';
import NotePad from './components/note-pad';
import SenderDetails from './components/sender-detail';
import LeadDetails from './components/lead-details';

const ConversationAgent = ({ agentInfo }: any) => {
    function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringSplit(name: string) {
        let name_split = name.split(' ') ? name.split(' ') : name
        if (name_split.length > 1) {
            let children = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
            return children

        } else {
            let children = `${name.split(' ')[0][0]}`
            return children
        }
    }

    function stringAvatar(name: string) {
        var children = stringSplit(name)

        return {
            sx: {
                bgcolor: stringToColor(name),
                width: 85, height: 85,
                fontSize: '30px',
                marginTop: '1em',
                marginBottom: '1em'
            },
            children: children,
        };
    }

    return (
        <Box sx={{ 'display': 'flex', 'align-items': 'center', 'flex-direction': 'column' }}>
            <Avatar {...stringAvatar(agentInfo.agent_name)} />
            <Typography variant='body2' sx={{ 'color': 'grey' }} >Assigned To</Typography>
            <Typography variant='body2' align='center' fontSize='larger'>{agentInfo.agent_name}</Typography>
        </Box>
    )
}

const AgentItem = ({ id, agentStatus, agentName }: any) => {
    const [selected, setSelected]: any = useState(false)
    const { handoverAgentId, setHandoverAgentId }: any = useContext(ConversationContext)

    useEffect(() => {
        if (handoverAgentId !== id) {
            setSelected(false);
        }
    }, [handoverAgentId]);

    useEffect(() => {
        if (selected === false) {
            setHandoverAgentId(null);
        }
    }, [selected]);

    return (
        <ListItem id="AgentItem" alignItems="center" button selected={selected} onClick={(e) => { setSelected(!selected); setHandoverAgentId(id); }}>
            <ListItemText
                primary={agentName}
            />
        </ListItem>
    )
}

const UniversityItem = ({ id, name }: any) => {
    const [selected, setSelected]: any = useState(false)
    const { setAgentId, universityId, setUniversityId }: any = useContext(ConversationContext)

    useEffect(() => {
        if (universityId !== id) {
            setSelected(false);
        }
    }, [universityId]);

    return (
        <ListItem id="UniversityItem" alignItems="center" button selected={selected} onClick={(e) => { setSelected(!selected); setUniversityId(id); setAgentId(null); }}>
            <ListItemText
                primary={name}
            />
        </ListItem>
    )
}

const ConversationHandover = ({ agentInfo }: any) => {
    const [selected, setSelected]: any = useState(false)

    const { wsRef, handoverAgentId, selectedConversationTag, selectedSenderId, agentId, selectedAgentId, universityId, setUniversityId, receiveHandover, setReceiveHandover }: any = useContext(ConversationContext)
    const agent_id = sessionStorage.getItem('agent_id')

    // useEffect(() => {
    //     if (!selectedConversationTag) return; 

    //     if (selectedAgentId === agent_id && selectedConversationTag === 'Reassigned'){
    //         setReceiveHandover(true)
    //     }
    // }, [selectedConversationTag]);

    async function confirmHandover() {
        wsRef.current.send(JSON.stringify({ action: 'accept_handover', target_id: selectedSenderId, agent_id: agentId, handover_agent_id: agentId }))
    }

    async function denyHandover() {
        wsRef.current.send(JSON.stringify({ action: 'deny_handover', target_id: selectedSenderId, agent_id: agentId, handover_agent_id: agentId }))
    }

    return (
        <Box sx={{ 'margin-top': '2em', 'margin-bottom': '2em', 'display': 'flex', 'align-items': 'center', 'flex-direction': 'column' }}>
            {receiveHandover === true &&
                <Stack sx={{ marginTop: '1em' }} direction="row" spacing={5}>
                    <Button size='small' onClick={(e) => { confirmHandover(); }} color='success' variant="contained">Confirm</Button>
                    <Button size='small' onClick={() => { denyHandover(); }} color='warning' variant="contained">Deny</Button>
                </Stack>
            }
        </Box>
    )
}

const ConversationHandler = ({ agentInfo }: any) => {
    const { wsRef, agentId, setAgentId, universityId, selectedSenderId, transferButtonClicked, setTransferButtonClicked, pendingRequest, selectedAgentId, selectedConversationTag, handoverAgentId, setHandoverAgentId, agentIsHandler, selectedUniversityId }: any = useContext(ConversationContext)
    const [agentAvailable, setAgentAvailable]: any = useState(null)
    const [studentType, setStudentType]: any = useState(1)
    const [listType, setListType]: any = useState(1)
    const [handoverButton, setHandoverButton]: any = useState(false)
    const university_id = sessionStorage.getItem('university_id')

    const [universities, setUniversities]: any = useState([{ 'id': 1, 'name': 'UCSI Kuala Lumpur' }, { 'id': 2, 'name': 'UCSI Kuching' }, { 'id': 3, 'name': 'UCSI Springhill' }, { 'id': 4, 'name': 'UCSI College' }])
    const agent_id = sessionStorage.getItem('agent_id')


    useEffect(() => {
        if (!agentId) return;

        if (agentId === agent_id) {
            setHandoverButton(true)
        }
    }, [agentId]);

    // async function handoverConversation() {
    //     const response = await fetch(`${BACKEND_URL}/conversation-flag?sender_id=${selectedSenderId}&agent_id=${agentId}&conversation_tag=Reassigned`, {
    //         method: "PUT"
    //     })

    //     const data = await response.json();
    //     console.log(data)
    //     setPendingRequest(true)
    // }

    const handoverConversation = () => {
        wsRef.current.send(JSON.stringify({ action: 'handover', target_id: selectedSenderId, agent_id: agentId, handover_agent_id: handoverAgentId }))
    }

    useEffect(() => {
        if (!agentInfo) return;

        if (agentInfo.local_counselor == true) {
            setStudentType(1);
        } else {
            setStudentType(2);
        }
    }, [agentInfo]);

    const loadAvailableAgent = async () => {
        const response = await fetch(`${BACKEND_URL}/lookup-available-agent?student_type=${studentType}&university=${selectedUniversityId ? selectedUniversityId : university_id}&agent_id=${selectedAgentId}`, {
            method: "GET"
        })
        const data = await response.json();
        setAgentAvailable(data);
        // let newArray = data.filter((item: any) => item.id.toString() !== agent_id);
        // setAgentAvailable(newArray);

        // if (universityId == null) {
        //     const response = await fetch(`${BACKEND_URL}/lookup-available-agent?student_type=${studentType}&university=${agentInfo.university}&agent_id=${selectedAgentId}`, {
        //         method: "GET"
        //     })
        //     const data = await response.json();
        //     console.log(data)
        //     setAgentAvailable(data.result);
        //     // let newArray = data.filter((item: any) => item.id.toString() !== agent_id);
        //     // setAgentAvailable(newArray);

        // } else {
        //     const response = await fetch(`${BACKEND_URL}/lookup-available-agent?student_type=${studentType}&university=${universityId}&agent_id=${selectedAgentId}`, {
        //         method: "GET"
        //     })

        //     const data = await response.json();
        //     console.log(data)
        //     setAgentAvailable(data.result);
        //     // let newArray = data.filter((item: any) => item.id.toString() !== agent_id);
        //     // setAgentAvailable(newArray);
        // }
    }

    useEffect(() => {
        loadAvailableAgent();
    }, []);


    return (
        <Box sx={{ 'margin-top': '2em', 'margin-bottom': '2em', 'display': 'flex', 'align-items': 'center', 'flex-direction': 'column' }}>
            {
                (agent_id === selectedUniversityId?.toString() || agentIsHandler === true) && transferButtonClicked === false &&
                <Button onClick={() => { setTransferButtonClicked(true); loadAvailableAgent() }} disabled={pendingRequest} color='warning' variant="contained" startIcon={<PhoneForwardedIcon />}>Handover Request</Button>
            }
            {transferButtonClicked === true &&
                <Paper color="grey" variant="outlined" elevation={10} style={{ margin: '1em', minHeight: '5em', width: '75%' }}>
                    {agentAvailable && agentAvailable.length > 0 && agentAvailable.map((agent: any) => (
                        <List dense>
                            <AgentItem
                                key={agent.id}
                                id={agent.id}
                                agentName={agent.agent_name}
                                agentStatus={agent.status}
                            />
                            <Divider variant="inset" component="li" />
                            {/* {i + 1 < chats.length && <Divider variant="inset" component="li" />} */}
                        </List>
                    ))
                    }
                </Paper>
            }
            {
                // transferButtonClicked === true && listType == 2 &&
                // <Paper color="grey" variant="outlined" elevation={10} style={{ margin: '1em', minHeight: '5em', width: '75%'}}>            
                //     {universities && universities.length > 0 && universities.map((university: any) => (
                //         <List dense>
                //             <UniversityItem
                //                 key={university.id}
                //                 name={university.name}
                //                 id={university.id}
                //             />
                //             <Divider variant="inset" component="li" />
                //             {/* {i + 1 < chats.length && <Divider variant="inset" component="li" />} */}
                //         </List>
                //         ))
                //     }
                // </Paper>
            }
            {
                // transferButtonClicked === true && listType == 1 && <Button size="small" color="success" variant="text" onClick={(e) => { setListType(2); setAgentId(null);}}>Select University</Button>
            }
            {
                // transferButtonClicked === true && listType == 2 && <Button size="small" color="success" variant="text" onClick={()=> setListType(1)}>Select Agents</Button>
            }
            {transferButtonClicked === true &&
                <Stack sx={{ marginTop: '1em' }} direction="row" spacing={5}>
                    <Button size='small' disabled={!handoverAgentId} onClick={(e) => { setTransferButtonClicked(false); handoverConversation(); }} color='success' variant="contained">Submit</Button>
                    <Button size='small' onClick={() => setTransferButtonClicked(false)} color='warning' variant="contained">Cancel</Button>
                </Stack>
            }
        </Box>
    )
}

type ConversationHeaderProps = {
    setArrowBackButtonClicked: (value: boolean) => void;
    setButtonClicked: (value: boolean) => void;
};

const ConversationSidebar = ({ setArrowBackButtonClicked, setButtonClicked }: ConversationHeaderProps) => {
    const { agentIsHandler, setAgentIsHandler, chats, wsRef, selectedSenderId, selectedConversationTag, selectedAgentId, agentInfo, setAgentInfo, loadAgent, setLoadAgent, loadAgentPermission, setLoadAgentPermission, receiveHandover, selectedUniversityId }: any = useContext(ConversationContext)
    const identity = sessionStorage.getItem('identity')
    const agent_id = sessionStorage.getItem('agent_id')
    const [agentIsHandover, setAgentIsHandover]: any = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    const handleArrowBackClick = () => {
        setArrowBackButtonClicked(true);
        setButtonClicked(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (loadAgent === true) {
            setLoadAgent(false)
        }
    }, [agentInfo]);

    return (
        <><div className='conversation-sidebar'>
            {
                selectedSenderId &&
                <Paper className='conversation-sidebar-paper-1'>
                    {isMobile && (
                        <IconButton id='handle-botton-sidebar' onClick={handleArrowBackClick}><ArrowBackIosIcon /></IconButton>
                    )}
                    {
                        selectedSenderId && selectedAgentId &&
                        <Box>
                            {agentInfo &&
                                <ConversationAgent agentInfo={agentInfo} />
                            }
                            {(agent_id === selectedUniversityId?.toString() || agentIsHandler === true) &&
                                <ConversationHandler agentInfo={agentInfo} />
                            }
                            {receiveHandover === true &&
                                <ConversationHandover agentInfo={agentInfo} />
                            }
                            <Divider variant="fullWidth" />
                        </Box>
                    }
                    <Box sx={{ 'margin-left': '2em', 'margin-top': '2em', 'margin-bottom': '2em', 'display': 'flex', 'align-items': 'flex-start', 'flex-direction': 'column' }}>
                        <SenderDetails />
                    </Box>
                    <LeadDetails />
                    <Divider variant="fullWidth" />
                    <Box sx={{ 'margin-left': '2em', 'margin-top': '2em', 'margin-bottom': '2em', 'display': 'flex', 'align-items': 'flex-start', 'flex-direction': 'column' }}>
                        <Typography variant="body1" gutterBottom>
                            SESSION DETAILS
                        </Typography>
                        <SessionStatus />
                        <CountdownTimer />
                    </Box>
                    <Divider variant="fullWidth" />
                    <Box sx={{ 'margin-left': '2em', 'margin-top': '2em', 'margin-bottom': '2em', 'display': 'flex', 'align-items': 'flex-start', 'flex-direction': 'column' }}>
                        <Typography variant="body1" gutterBottom>
                            NOTES
                        </Typography>
                        <NotePad />
                    </Box>
                    <Divider variant="fullWidth" />
                    <Box sx={{ 'overflowY': 'auto', 'margin-left': '2em', 'margin-top': '2em', 'margin-bottom': '2em', 'display': 'flex', 'align-items': 'flex-start', 'flex-direction': 'column' }}>
                        <Typography variant="body1" gutterBottom>
                            TAGS
                        </Typography>
                        <MultipleSelectChip />
                    </Box>
                </Paper>
            }
        </div>
        </>
    )
}

export default ConversationSidebar